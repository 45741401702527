<template>
  <div class="contenedorInformesGenerales">
    <div class="subSection">REPORTES GLOBALES</div>

    <div class="informe-container">
      <select v-model="dependencias" class="informe" @change="loadPeriodos">
        <option value="0" selected hidden>Tipo Informe</option>
        <option value="dHumano">
          Informe Actividades de Desarrollo Humano
        </option>
        <option value="APsicologia">Informe Asesorias Psicologicas</option>
        <option value="AEnfermeria">Informe Atencion a Enfermeria</option>
      </select>
      <!-- periodos academicos -->
      <select
        v-show="dependencias == 'dHumano' || dependencias == 'APsicologia'"
        v-model="periodo"
        class="informe"
      >
        <option value="0" selected hidden>Periodo Academico</option>
        <option v-for="semestre in semestres" :key="semestre" :value="semestre">
          {{ semestre }}
        </option>
      </select>

      <!-- psicologos -->
      <select
        v-show="dependencias == 'APsicologia'"
        v-model="psicologoSelected"
        class="informe"
      >
        <option value="0" selected hidden>Profesional</option>
        <option
          v-for="psicologo in psicologos"
          :key="psicologo"
          :value="psicologo"
        >
          {{ psicologo }}
        </option>
      </select>
      <!-- fecha desde -->
      <div
        v-if="dependencias == 'dHumano' || dependencias == 'APsicologia'"
        class="informe"
      >
        <span style="font-weight: 600">Desde:</span>
        <input v-model="fecha1" class="filtro-fecha-1" type="date" />
      </div>
      <!-- fecha hasta -->
      <div
        v-if="dependencias == 'dHumano' || dependencias == 'APsicologia'"
        class="informe"
      >
        <span style="font-weight: 600">Hasta:</span>
        <input v-model="fecha2" class="filtro-fecha-2" type="date" />
      </div>
    </div>
    <div v-if="dependencias != '0'" class="container-generar">
      <div class="informe btn-generar" @click="genInforme">
        <h6>Generar Informe</h6>
        <i class="bx bxs-send"></i>
      </div>
    </div>
  </div>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import localToken from "@/logic/localToken.js";
import "vue-good-table/dist/vue-good-table.css";
//import { VueGoodTable } from "vue-good-table";
export default {
  name: "ReportesGenerales",
  data: function () {
    return {
      fecha1: null,
      fecha2: null,

      showFiltro: false,
      dependencias: "0",
      periodo: "0",
      periodoAP: "0",

      semestres: null,
      semestresAP: null,
      psicologos: null,
      psicologoSelected: "0",
    };
  },

  methods: {
    async loadPeriodos() {
      try {
        if (this.dependencias == "dHumano") {
          const r = await ApiUsers.getPeriodosADH(localToken.getToken());
          this.semestres = r.data;
        } else if (this.dependencias == "APsicologia") {
          const r = await ApiUsers.getPeriodosAP(localToken.getToken());
          this.semestres = r.data;
        }
      } catch (error) {
        console.error(error);
        alert("Err Internal");
        this.ShowLoadAnimation(true);
      }
    },
    async loadPsicologos() {
      try {
        const r = await ApiUsers.getPsicologos();
        this.psicologos = r.data;
      } catch (error) {
        console.log(error);
      }
    },
    async genInforme() {
      try {
        this.showDeps = false;
        this.ShowLoadAnimation(true);

        switch (this.dependencias) {
          case "dHumano": {
            if (this.periodo == "0") {
              this.periodo = null;
            }
            let objTemp = {
              periodoAP: this.periodo,
              fecha1: this.fecha1,
              fecha2: this.fecha2,
            };
            const resp = await ApiUsers.getInformeGeneralADH(
              localToken.getToken(),
              objTemp
            );
            console.log(resp);
            this.ShowLoadAnimation(false);
            break;
          }
          case "APsicologia": {
            if (this.periodo == "0") {
              this.periodo = null;
            }
            if (this.psicologoSelected == "0") {
              this.periodo = null;
            }
            let objTemp = {
              psicologo: this.psicologoSelected,
              periodo: this.periodo,
              fecha1: this.fecha1,
              fecha2: this.fecha2,
            };
            const resp = await ApiUsers.getInformeGeneralAP(
              localToken.getToken(),
              objTemp
            );
            console.log(resp);
            this.ShowLoadAnimation(false);
            break;
          }
          case "AEnfermeria": {
            await ApiUsers.getInformeGeneralEnfermeria(localToken.getToken());

            this.ShowLoadAnimation(false);
            break;
          }

          default:
            break;
        }
        this.ShowLoadAnimation(false);
      } catch (error) {
        console.error(error);
        alert("Err Internal");
        this.ShowLoadAnimation(true);
      }
    },

    ShowLoadAnimation(valor) {
      if (valor) {
        this.$store.dispatch("setLoadAnimationAction", { s: true });
      } else {
        setTimeout(() => {
          this.$store.dispatch("setLoadAnimationAction", { s: false });
        }, 10);
      }
    },
  },
  created() {
    if (localToken.getToken() == "null") {
      this.$router.push("/login");
    } else {
      this.ShowLoadAnimation(true);
    }
  },
  mounted() {
    this.loadPsicologos();
    this.ShowLoadAnimation(false);
  },
};
</script>


<style scoped>
.filtro-fecha-1,
.filtro-fecha-2 {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
  height: 100%;
  border: none;
}
.filtro-fecha-1,
.filtro-fecha-2:hover {
  cursor: pointer;
  outline: none;
  border: none;
}
.filtro-fecha-1,
.filtro-fecha-2:active {
  outline: none;
  border: none;
}

.btn-filtrar {
  cursor: pointer;
  background-color: rgba(228, 36, 19, 0.1);
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn-filtrar:hover {
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.btn-filtrar:active {
  border: solid 1px rgba(0, 0, 0, 0.432);
}

.informe-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0px;
  padding: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.opciones {
  width: 100%;
  height: 5vh;
}
.informe h6 {
  text-align: center;
}
.informe {
  border: none;
  display: flex;
  width: 100%;
  padding: 15px;
  margin-top: 10px;
  box-shadow: 1px 3px 10px rgba(128, 128, 128, 0.2);
}
.informe:hover {
  cursor: pointer;
  box-shadow: 1px 3px 10px rgba(128, 128, 128, 0.3);
}
.container-generar {
  display: flex;
  width: 98%;
  align-items: flex-end;
  height: 100%;
  margin-bottom: 20px;
}
.btn-generar h6 {
  margin: 0px;
}
.btn-generar i {
  margin-left: 15px;
}
.btn-generar {
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  background-color: #0197f6;
}
.contenedorInformesGenerales {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 6px 12px #c5c5c55d;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  height: 100%;
  overflow-y: auto;
  padding: 5px;
}

.subSection {
  display: flex;
  justify-content: center;
  width: 98%;
  height: min-content;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
  border-radius: 2px;
  background-color: rgba(228, 36, 19, 0.05);
  color: #090909;
  padding: 10px 10px 10px 10px;
  transition: all 0.3s;
  border: 1px solid rgba(128, 128, 128, 0.3);
}

.subSection:hover {
  cursor: pointer;
}

.subSection:active {
  box-shadow: 0px 6px 16px -12px #838181, -4px -4px 12px #ffffff;
}

.btn-actividades {
  margin: 3px;
  padding: 3px;
  border: 1px solid rgba(128, 128, 128, 0.336);
  border-radius: 3px;
  background-color: rgba(228, 36, 19, 0.05);
}
.btn-actividades:hover {
  cursor: pointer;
  background-color: rgba(228, 36, 19, 0.1);
}
.botonera {
  justify-content: space-around;
  background-color: rgba(228, 36, 19, 0);
  border: none;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .botonera {
    justify-content: flex-start;
  }

  .informe-container {
    flex-direction: row;
  }
  .informe {
    width: max-content;
  }
  .opciones {
    width: max-content;
  }
}
</style>