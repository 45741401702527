<template>
  <div class="mycontainer">
    <section :id="numeroidentificacion">
      <td class="profile-container">
        <img
          class="user-profile"
          :src="require('@/assets/img/user.png')"
          alt="unser-profile"
        />
      </td>
      <td class="usuario">
        <div>
          <label class="lb-info" for="'usr-' + numeroidentificacion"
            >Nombre:</label
          >
          <input
            class="entrada"
            :id="'usr-' + numeroidentificacion"
            v-bind:disabled="disable"
            v-model="nombreModel"
          />
        </div>
      </td>
      <td class="rol regt">
        <div>
          <label class="lb-info" for="'rol-' + numeroidentificacion"
            >Rol:</label
          >

          <input
            v-if="!optionsrol"
            type="text"
            class="entrada"
            :id="'rol-' + numeroidentificacion"
            :value="rol"
            disabled
          />
          <select v-if="optionsrol" name="rols" id="rols" v-model="rolModel">
            <option value="psicologo">Psicologo</option>
            <option value="deporte">Deporte</option>
            <option value="salud">Salud</option>
            <option value="cultura">Cultura</option>
            <option value="admin">Admin</option>
          </select>
        </div>
      </td>
      <td class="numid regt">
        <div>
          <label class="lb-info" for="'nid-' + numeroidentificacion"
            >Nid:</label
          >
          <input
            class="entrada"
            type="text"
            :id="'nid-' + numeroidentificacion"
            disabled
            v-model="nidModel"
          />
        </div>
      </td>
      <!-- <td class="pass regt">
        <div>
          <label class="lb-info" for="'pas-' + numeroidentificacion"
            >Password:</label
          >
          <input
            class="entrada"
            :id="'pas-' + numeroidentificacion"
            v-bind:disabled="disable"
            v-model="pasModel"
          />
        </div>
      </td> -->
      <td class="correo regt">
        <div>
          <label class="lb-info" for="'mail-' + numeroidentificacion"
            >Correo Electrónico:</label
          >
          <input
            class="entrada"
            :id="'mail-' + numeroidentificacion"
            v-bind:disabled="disable"
            v-model="mailModel"
          />
        </div>
      </td>
      <td class="mynavbar">
        <li class="mydrop">
          <i class="bx bxs-down-arrow nav-link scrollto" @click="showmenu"></i>
        </li>
      </td>
    </section>
    <div v-if="menushow">
      <ul class="option-user">
        <li>
          <span v-if="showeditar" class="drop" @click="editar">EDITAR</span>
        </li>
        <li>
          <span v-if="showguardar" class="drop" @click="guardar">GUARDAR</span>
        </li>
        <li v-if="!showconfirmeliminar">
          <span class="drop" @click="preliminar">ELIMINAR</span>
        </li>
        <li v-if="showconfirmeliminar">
          <span class="drop pregunta">¿DESEA ELIMINAR ESTE USUARIO?</span>
        </li>
        <li v-if="showconfirmeliminar">
          <span
            class="drop no"
            @click="
              showconfirmeliminar = false;
              showeditar = true;
            "
            >NO</span
          >
        </li>
        <li v-if="showconfirmeliminar">
          <span class="drop si" @click="eliminar">SI</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/logic/ApiUsers.js";
import localToken from "@/logic/localToken.js";

export default {
  name: "RowUserList",
  data: function () {
    return {
      menushow: false,
      showguardar: false,
      showeditar: true,
      disable: true,
      optionsrol: false,
      showconfirmeliminar: false,
      nombreModel: this.nombreusuario,
      rolModel: this.rol,
      nidModel: this.numeroidentificacion,
      pasModel: this.password,
      mailModel: this.correoelectronico,
    };
  },
  props: {
    nombreusuario: String,
    rol: String,
    numeroidentificacion: String,
    password: String,
    correoelectronico: String,
  },
  methods: {
    showmenu() {
      this.menushow = !this.menushow;
    },
    editar() {
      this.showeditar = !this.showeditar;
      this.showguardar = !this.showguardar;
      this.disable = false;
      this.optionsrol = true;
      const usr = document.getElementById(`usr-${this.numeroidentificacion}`);

      const pas = document.getElementById(`pas-${this.numeroidentificacion}`);
      const mail = document.getElementById(`mail-${this.numeroidentificacion}`);

      usr.classList.add("inp-newuser");
      pas.classList.add("inp-newuser");
      mail.classList.add("inp-newuser");
    },
    async guardar() {
      try {
        this.ShowLoadAnimation(true);
        const actualizacion = {
          nombreusuario: this.nombreModel,
          rol: this.rolModel,
          numeroidentificacion: this.nidModel,
          password: this.pasModel,
          correoelectronico: this.mailModel,
          token: localToken.getToken(),
        };
        const respuesta = await Api.update(this.nidModel, actualizacion);
        console.log(respuesta);
        this.menushow = false;
        this.$emit("recargar");
        this.ShowLoadAnimation(false);
      } catch (error) {
        alert(error);
      }
    },
    preliminar() {
      this.showconfirmeliminar = true;
      this.showeditar = false;
    },
    async eliminar() {
      try {
        this.ShowLoadAnimation(true);
        // console.log(this.nidModel)
        const respuesta = await Api.delete(this.nidModel, {
          token: localToken.getToken(),
        });
        console.log(respuesta);
        this.$emit("recargar");
        this.ShowLoadAnimation(false);
      } catch (error) {
        console.log(error);
      }
    },
    ShowLoadAnimation(valor) {
      if (valor) {
        this.$store.dispatch("setLoadAnimationAction", { s: true });
      } else {
        setTimeout(() => {
          this.$store.dispatch("setLoadAnimationAction", { s: false });
        }, 500);
      }
    },
  },
  updated() {
    if (this.menushow) {
      document.getElementById(
        `${this.numeroidentificacion}`
      ).style.borderBottomLeftRadius = "0px";
      document.getElementById(
        `${this.numeroidentificacion}`
      ).style.borderBottomRightRadius = "0px";
    } else {
      this.optionsrol = false;
      this.disable = true;
      this.showeditar = true;
      this.showguardar = false;

      const usr = document.getElementById(`usr-${this.numeroidentificacion}`);
      //   const rol = document.getElementById(`rol-${this.numeroidentificacion}`);
      //   const nid = document.getElementById(`nid-${this.numeroidentificacion}`);
      const pas = document.getElementById(`pas-${this.numeroidentificacion}`);
      const mail = document.getElementById(`mail-${this.numeroidentificacion}`);

      usr.classList.remove("inp-newuser");
      pas.classList.remove("inp-newuser");
      mail.classList.remove("inp-newuser");

      document.getElementById(
        `${this.numeroidentificacion}`
      ).style.borderBottomLeftRadius = "10px";
      document.getElementById(
        `${this.numeroidentificacion}`
      ).style.borderBottomRightRadius = "10px";
    }
  },
};
</script>

<style scoped>
#rols {
  border: none;
  border-radius: 3px;
  color: blue;
  background-color: rgba(128, 128, 128, 0.07);
}
section {
  display: flex;
  width: 100%;
  height: max-content;
  min-height: 50px;
  justify-content: space-between;
  padding: 2px 10px 2px 10px;
  background-color: rgba(128, 128, 128, 0.123);
  border-radius: 15px;
  box-shadow: 0px 0px 6px rgba(68, 12, 12, 0.1);
}
section:hover {
  box-shadow: 0px 3px 10px rgba(128, 128, 128, 0.3);
}

section > td,
section > td > input {
  font-family: "Poppins";
  font-size: 14px;
  display: flex;
  align-items: center;
}
.option-user {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  background-color: rgba(68, 12, 12, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 0px;
}
.option-user > li {
  margin: 0px 5px 0px 5px;
  list-style-type: none;
}
.mycontainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.mynavbar > li {
  list-style-type: none;
}

.usuario {
  min-width: 150px;
  width: 25%;
  max-width: 25%;
}
.rol {
  width: 10%;
  min-width: 60px;
  max-width: 10%;
}
.numid {
  width: 15%;
  min-width: 150px;
  max-width: 15%;
}
.pass {
  min-width: 15%;
  max-width: 15%;
}
.estado {
  min-width: 10%;
  max-width: 10%;
}
.correo {
  min-width: 20%;
  max-width: 20%;
  width: max-content;
}
.opt {
  min-width: 5%;
  max-width: 5%;
}
.mydrop > i:hover {
  cursor: pointer;
}

.regt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  text-decoration: none;
  min-width: 100px;
  list-style: none;
}
.edit-menu {
  transform: translateX(-120px);
  max-width: 150px;
  z-index: 200;
}
.edit-menu > li > span {
  padding: 10px;
  font-size: 14px;
  color: rgb(31, 31, 77);
}
.edit-menu > li > span:hover {
  color: rgba(228, 35, 19, 1);
}
.drop {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  flex-wrap: wrap;
  white-space: pre-wrap;
  text-align: center;
  font-weight: 300;
}
.drop:hover {
  color: blue;
  cursor: pointer;
}
.pregunta {
  color: black;
  font-weight: 600;
}
.pregunta:hover {
  color: black;
  font-weight: 600;
}
.si,
.no {
  font-weight: 600;
  color: rgba(128, 128, 128, 0.815);
}
.si:hover {
  color: red;
}
.no:hover {
  color: green;
}
.entrada {
  display: flex;
  color: black;
  background-color: rgba(0, 255, 255, 0);
  border: none;
  width: max-content;
}
.entrada:focus {
  outline: none;
  border: none;
  text-decoration: underline;
}
.inp-newuser {
  color: blue;
  border: none;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.07);
}
.lb-info {
  display: none;
}
.user-profile {
  display: none;
}
.profile-container {
  display: none;
}
@media (min-width: 360px) and (max-width: 768px) {
  .mycontainer {
    margin-right: 20px;
  }
  section {
    flex-direction: column;
    height: 70vh;
    width: 80vw;
    min-width: 80vw;

    padding: 2px 30px 30px 30px;
  }
  .lb-info {
    display: block;
    font-weight: 600;
  }
  .profile-container {
    display: flex;
    width: 100%;
    height: min-content;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .user-profile {
    display: flex;
    border-radius: 110px;
    border: 1px solid rgba(128, 128, 128, 0.274);
    height: 15vh;
    width: 15vh;
  }
  .regt {
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
  }
}
</style>
