var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenedorHeaderDH"},[_c('div',{staticClass:"subSection"},[_vm._v("CARACTERIZACION - DESARROLLO HUMANO")]),_c('div',{staticClass:"subSection botonera"},[_c('div',{staticClass:"btn-actividades filtro-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fecha1),expression:"fecha1"}],staticClass:"filtro-fecha-1",attrs:{"type":"date"},domProps:{"value":(_vm.fecha1)},on:{"input":function($event){if($event.target.composing)return;_vm.fecha1=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fecha2),expression:"fecha2"}],staticClass:"filtro-fecha-2",attrs:{"type":"date"},domProps:{"value":(_vm.fecha2)},on:{"input":function($event){if($event.target.composing)return;_vm.fecha2=$event.target.value}}}),_c('button',{staticClass:"btn-filtrar",on:{"click":function($event){return _vm.obtenerActividades()}}},[_vm._v("Filtrar")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.seleccionado.length > 0 && _vm.showListar),expression:"seleccionado.length > 0 && showListar"}],staticClass:"btn-actividades",on:{"click":_vm.descargar}},[_vm._v(" GENERAR INFORME ")])]),_c('vue-good-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.showListar),expression:"showListar"}],attrs:{"id":"tabla-actividades","columns":_vm.columns,"rows":_vm.rows,"fixed-header":true,"max-height":"alturaTabla","search-options":{ enabled: true, placeholder: 'Buscar...' },"select-options":{
      enabled: true,
      selectionText: 'Actividades Seleccionadas',
      clearSelectionText: 'limpiar',
    },"pagination-options":{
      enabled: _vm.showPagination,
    }},on:{"on-selected-rows-change":_vm.selection}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }