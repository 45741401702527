<template>
  <!-- <Alerta class="alerta" v-show="$store.state.showrutadescape" :titulo="smsTitle" :cuerpo="smsBody" :tBoton="smsBText" />
     -->
  <div class="cobertor">
    

    <div v-if="step0 || step1" class="card-id-eval">
      
      <div class="bg-bottom-eval">
        <div class="container-my-svg">
          <div class="relleno">

          </div>
          <svg
          class="my-svg"
          id="mysvg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#e42313"
            fill-opacity="1"
            d="M0,128L60,160C120,192,240,256,360,256C480,256,600,192,720,176C840,160,960,192,1080,176C1200,160,1320,96,1380,64L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
          ></path>
        </svg>
        </div>
        
        <img class="perfil-card-eval" :src="require('@/assets/img/man.png')" />
        <h2 class="title-custom-card" v-if="step0 || step1">{{ textTitle }}</h2>
        <div class="cob-entrada-id">
          <!-- <h6>ID de registro:</h6> -->

          <div v-if="step0" class="cob-inp-cod-estud">
            <input
              class="inp-cod-estud"
              type="number"
              placeholder="* * * * *"
              v-model="idAsistente"
            />
            <h6 v-show="!haveError" class="leyenda">
              click para ingresar ID de registro.
            </h6>
            <h6 v-show="haveError" class="leyenda-have-error">
              no se encontro registro
            </h6>
          </div>
        </div>
        <div
          v-if="step0"
          class="cob-mv-button-next"
          @click="verificarIdAsistente"
        >
          <button class="mv-button-next">CONTINUAR</button>
          <i class="bx bx-right-arrow-alt"></i>
        </div>

        <div v-if="step1" class="container-list-activity">
          <ul
            class="list-activity"
            
          >
            <li class="item-list-activity" v-for="item in listActivity"
            :key="item._id">
              <div
                class="content-item-list-activity"
                @click="loadQuestions(item._id)"
              >
                {{ `${item.fechaCita} ${item.horaCita} con ${item.psicologo}`  }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-questions" v-if="step2 || step3">
      <div>
        
        <p v-if="step2 " class="question-content"  >{{ textCurrentQuestion }}</p>
        
      </div>
      <ul v-if="step21" class="list-activity">
        <li class="item-list-activity">
          <div class="content-item-list-activity" @click="calificar('1')">PAGINA WEB INSTITUCIONAL</div>
        </li>
        <li class="item-list-activity">
          <div class="content-item-list-activity"  @click="calificar('2')">EMAIL</div>
        </li>
        <li class="item-list-activity">
          <div class="content-item-list-activity"  @click="calificar('3')">TELEFONO</div>
        </li>
        <li class="item-list-activity">
          <div class="content-item-list-activity"  @click="calificar('4')">AMIGO/CONOCIDOS</div>
        </li>
        <li class="item-list-activity">
          <div class="content-item-list-activity"  @click="calificar('5')">INVITACION DIRECTA</div>
        </li>
        <li class="item-list-activity">
          <div class="content-item-list-activity"  @click="calificar('6')">CARTELELERA</div>
        </li>
      </ul>
      <ul v-if="step2 && !step21" class="list-activity">
        <li class="item-list-activity">
          <div class="content-item-list-activity" @click="calificar('E')">EXCELENTE</div>
        </li>
        <li class="item-list-activity">
          <div class="content-item-list-activity"  @click="calificar('B')">BUENO</div>
        </li>
        <li class="item-list-activity">
          <div class="content-item-list-activity"  @click="calificar('R')">REGULAR</div>
        </li>
        <li class="item-list-activity">
          <div class="content-item-list-activity"  @click="calificar('M')">MALO</div>
        </li>
      </ul>
      <div class="container-img-finish">
      <img class="img-finish" v-if="currentQuestion ==10" :src="require('@/assets/imgCheckList.svg')" >
      </div>
      <textarea v-model="comentario" v-if="currentQuestion ==10" class="text-area-comentario" placeholder="deja un comentario" cols="30" rows="5"></textarea>
      <div v-if="currentQuestion >0 && currentQuestion <= 10" class="item-list-activity control-group">
        <button v-show="currentQuestion > 0 && currentQuestion < 10" class="content-item-list-activity" @click="retorn">Anterior</button>
        <button v-show="currentQuestion ==10" class="content-item-list-activity" @click="sendAnswers">Finalizar</button>
      </div>
      <div v-if="step2 || step3 && currentQuestion < 10"  class="question-content container-progress-bar">
          <div class="progressbar" id="progressBar"></div>
        </div>
    </div>
  </div>
</template>

<script>


import ApiUsers from "@/logic/ApiUsers.js";
export default {
  name: "EvalPsico",
  data: function () {
    return {
      textTitle: "Paciente",
      idAsistente: null,
      idActivity: null,
      haveError: false,
      listActivity: null,
      step0: true,
      step1: false,
      step2: false,
      step3: false,
      step21: false,

      anima: {
        classes: "fadeInRight",
        duration: 1500,
        iteration: "1",
      },

      questions: {
        0: "¿Cómo se ha enterado de este servicio?",
        1: "Como califica la organización del servicio",
        2: "Contenido del servicio.",
        3: "Metodología empleada para la realización del servicio.",
        4: "Desempeño del Psicologo.",
        5: "Consideras adecuadas las instalaciones donde se desarrolla la asesoria.",
        6: ": Las condiciones de iluminación para el desarrollo de la asesoria:",
        7: "Las condiciones de audio para el desarrollo de la asesoria es:",
        8: "Las condiciones de aseo para el desarrollo de la asesoria:",
      },
      answers: [],
      currentQuestion: 0,
      textCurrentQuestion: '',
      comentario:null
    };
  },
  methods: {
    retorn(){
      this.currentQuestion = this.currentQuestion-1;
      if(this.currentQuestion == 0){
        this.step21 = true;
      }
      document.getElementById('progressBar').style.width= `${this.currentQuestion*10}%`
      this.textCurrentQuestion = this.questions[this.currentQuestion];
      this.answers.pop()
      console.log(this.answers)
    },
    async sendAnswers(){
      try {
        const objT = {
          idSolicitud:this.idActivity,
          calificaciones:this.answers,
          comentario: this.comentario
        }
        //console.log(objT)
        const r = await ApiUsers.saveEvaluacionPsicoAsistidas(objT)
        alert(r.data)
        this.$router.push('/servicios')
      } catch (error) {
        console.log(error)
      }
    },
    calificar(nota){
      if(this.currentQuestion == 0){
        this.step21= false;
      }
      this.currentQuestion =this.currentQuestion +1;
      document.getElementById('progressBar').style.width= `${this.currentQuestion*10}%`
      this.textCurrentQuestion = this.questions[this.currentQuestion];
      this.answers.push(nota)
      //console.log(this.answers)
      if(this.currentQuestion ==10){
      this.step2= false;
      this.step3= true;
      }
    },
    loadQuestions(param) {
      this.idActivity = param;
      this.step0 = false;
      this.step1 = false;
      this.step2 = true;
      this.step21 = true;
      this.textCurrentQuestion = this.questions[0];
      document.documentElement.scrollTop = 0;
    },
    async verificarIdAsistente() {
      try {
        this.haveError = false;
        const resp = await ApiUsers.getSolicitudesPsicoAsistidas(this.idAsistente);
        console.log('respOld: ', resp);
        if (resp.data.length == 0) {
          this.haveError = true;
        } else {
          console.log(resp.data);
          this.listActivity = resp.data;
          console.log(this.listActivity);
          this.textTitle = "Seleccione una Asesoria";
          this.step0 = false;
          this.step1 = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    // const mysvg = document.getElementById("mysvg");

    // if (/android/i.test(navigator.userAgent)) {
    //   mysvg.style.marginBottom = `${mysvg.clientHeight}px`;
    // } else {
    //   if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    //     mysvg.style.marginBottom = `${mysvg.clientHeight}px`;
    //   } else {
    //     mysvg.style.marginBottom = `${mysvg.clientHeight * 0.5}px`;
    //   }
    // }
  },
  destroyed() {
    try {
      this.$store.state.publicdhselected = null;
    } catch (error) {
      console.log("sin puntero");
    }
  },
  components:{
    
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap");
.title-custom-card{
  margin-top: 8vh;
}
.relleno{
  display: flex;
  width: 100%;
  min-width: 100%;
  height: 20vh;
  background-color: #e42313;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.container-my-svg{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.control-group{
margin-top: 8vh;
margin-bottom: 3vh;
}
.text-area-comentario{
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  resize: none;
}
.container-img-finish{
  display: flex;
  width: 100%;
  justify-content: center;
}
.img-finish{
  display: flex;
  width: 80%;
  
}

.progressbar{
  display: flex;
  height: 2px;
  background-color: black;
  border-radius: 1px;
  width: 0%;
  transition: all 2s
}
.container-questions{
  display: flex;
  flex-direction: column;
  padding: 3vh;
  width: 100%;
}

.question-content{
  margin-top: 10px;
  width: 100%;
  background-color: #fff;
  border: solid 1px rgba(128, 128, 128, 0.548);
  border-radius: 5px;
  text-align: justify;
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  height: 20vh;
}
.container-list-activity {
  display: flex;
  width: 100%;
  height: 30%;
  margin: 5px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.list-activity {
  list-style: none;
  margin: 0px;
  padding: 0px;
  padding-top: 5vh;
  display: flex;
  
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.item-list-activity {
  display: flex;
  width: 100%;
  height: min-content;
  
  justify-content: center;
  align-items: center;
  font-size: 28px;
}
.item-list-activity :hover{cursor: pointer;}
.content-item-list-activity :hover{cursor: pointer;}
.content-item-list-activity {
  background-color: #fff;
  display: flex;
  width: 90%;
  text-align: center;
  margin-bottom: 15px;
  border-radius: 5px;
  /* height: 5vh; */
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 8px rgba(128, 128, 128, 0.267);
  font-size: 1rem;
  transition: all 1s;
  padding: .5rem;
}
.content-item-list-activity:active {
  width: 80%;
  
}

.content-item-list-activity:active {
  cursor: pointer;
  box-shadow: 3px 3px 10px rgba(34, 197, 48, 0.267);
}

.cobertor {
  display: flex;
  width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  background-color: #f4fbfe;
}
.card-id-eval {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 90vh;
  border-radius: 5px;
}

.perfil-card-eval {
  position: absolute;
  transform: translate(0%, 15vh);
  border-radius: 70%;
  height: 22vh;
  
  
}
.my-svg {
  top: 0;
  height: auto;
  transform: translateY(-3px);
  border-radius: 5px;
}
.bg-bottom-eval {
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  height: 100%;
  background-color: rgb(255, 255, 255);
}
.cob-entrada-id {
  margin-top: 12vh;
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cob-inp-cod-estud {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.inp-cod-estud {
  text-align: center;
  width: 50%;
  font-family: "Cutive Mono", monospace;
  font-size: 30px;
  border: none;
  border-bottom: 1px solid rgba(128, 128, 128, 0.253);
}
.inp-cod-estud:focus,
.inp-cod-estud:active {
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(128, 128, 128, 0.253);
}
.leyenda {
  margin-top: 2px;
  font-size: 8px;
}
.leyenda-have-error {
  color: red;
  margin-top: 2px;
  font-size: 9px;
}
.mv-button-next {
  border: none;
  background-color: rgba(255, 0, 0, 0);
}
.mv-button-next:active {
  font-weight: 600;
}
.cob-mv-button-next {
  margin-top: 10vh;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid gray;
  border-radius: 1px;
}
.cob-mv-button-next:active,
.cob-mv-button-next:focus {
  border-bottom: 1px solid rgb(33, 21, 201);
}

.section-title > h2 {
  margin-top: 20px;
  font-size: 26px;
}



.container-progress-bar{
  background-color: #e4241300;
  border: none;
}











@media (min-width: 768px) {
  .cobertor{
    padding: 10vh;
  }

  .bg-top-eval {
    width: 90%;

    height: 0%;
    padding-top: 3vw;
  }

  .bg-bottom-eval {
    width: 90%;
    padding-bottom: 5vh;
  }
  
  .perfil-card-eval {
   
    transform: translateY(10vh);
   
  }
  
  .cob-mv-button-next {
    margin-top: 5vh;
  }
  .mv-button-next {
    font-size: 18px;
  }
  .mv-button-next:active {
    font-weight: 600;
  }
  .mv-button-next:hover {
    font-style: oblique;
  }
  .leyenda {
    font-size: 12px;
  }
  .leyenda-have-error {
    font-size: 12px;
  }
  .content-item-list-activity {
  width: 60%;
  height: 7vh;
  transition: all 0.3;
}
.content-item-list-activity:active {
  width: 60%;
  height: 12vh;
  
}
.container-questions{
  
  padding: 0%;
}
.img-finish{
  
  width: 35%;
  
}
.relleno{
  height: 5vh;
}
.title-custom-card{
  margin-top: 2vh;
}
.card-id-eval{
  height: 85vh;
}
.text-area-comentario{
  text-align: center;
  margin-left: 20vw;
  margin-right: 20vw;
  height: 10vh;
  margin-bottom: 0px;
}
}
</style>