<template>
  <section class="container-slidebar">
    <div class="bar-dashboard">
      <img
        class="img-fluid logo-img"
        :src="require('@/assets/img/logo-baner.png')"
        alt="img-logo"
      />
      <nav class="navbar toggle-menu">
        <span @click="toggleoptions = !toggleoptions" class="toggle-button"
          ><i class="bx bx-menu"></i
        ></span>
      </nav>
    </div>
    <!-- estas opciones se muestran solo si (toggleoptions) esta en vista de escritorio -->
    <ul v-if="toggleoptions" class="opciones">
      <li>
        <!-- opcion mostrada solo si el estado rol del vuex es psicologo o admin -->
        <div v-if="$store.getters.isPsicologo || $store.getters.isAdmin" class="item" @click="seleccionar(1)">
          <i class="bx bx-group"></i><label>Desarrollo Humano</label>
        </div>
      </li>
       <li>
        <div v-if="$store.getters.isPsicologo || $store.getters.isAdmin" class="item" @click="seleccionar(7)">
          <i class="bx bx-group"></i><label>Caracterizacion</label>
        </div>
      </li>
       <li>
        <div v-if="$store.getters.isPsicologo || $store.getters.isAdmin" class="item" @click="seleccionar(9)">
          <i class="bx bx-group"></i><label>A. Psicologica</label>
        </div>
      </li>
      
      <li>
        <div v-if="$store.getters.isDeporte || $store.getters.isAdmin" class="item" @click="seleccionar(2)">

    
          <i class="bx bx-basketball"></i><label>Recreacion y Deporte</label>
        </div>
      </li>
      <li>
        <div v-if="$store.getters.isCultura || $store.getters.isAdmin" class="item" @click="seleccionar(3)">
          <i class="bx bxs-book-bookmark"></i><label>Cultura</label>
        </div>
      </li>
      <li>
        <div v-if="$store.getters.isSalud || $store.getters.isAdmin" class="item" @click="seleccionar(4)">
          <i class="bx bxs-heart"></i><label>Salud</label>
        </div>
      </li>
      <li>
        <div v-if="$store.getters.isAdmin" class="item" @click="seleccionar(5)">
          <i class="bx bxs-user-detail"></i><label>Usuarios</label>
        </div>
      </li>
       <li>
        <div v-if="$store.getters.isAdmin" class="item" @click="seleccionar(8)">
          <i class='bx bxs-file-doc'></i><label>Reportes Generales</label>
        </div>
      </li>
      <li>
        <div v-if="$store.getters.isAdmin" class="item" @click="seleccionar(6)">
          <i class="bx bx-cloud-download"></i><label>Carga y Descarga</label>
        </div>
      </li>
      <li>
        <div class="item" @click="seleccionar(0)">
          <i class="bx bx-log-out"></i><label>Cerrar Sesion</label>
        </div>
      </li>
      <!-- <li><div class="item"><i class='bx bx-slider-alt'></i><label>Configuracion</label></div></li> -->
    </ul>
  </section>
</template>

<script>
import localToken from "@/logic/localToken.js";


export default {
  name: "SlideBard",
  data: function () {
    return {
      toggleoptions: true
    };
  },
  methods: {
    seleccionar(valor) {
      
      this.verificarDisposito();
    // 
    this.$store.state.optionSlidebarSelected_temp = valor;
    this.$store.dispatch('setOptionSlidebarSelectedAction');
      
      if (this.$store.state.optionSlidebarSelected == 0) {
        localToken.setToken(null);
        this.$router.push("/");
      }
    },
    verificarDisposito(){
      // verificamos el tipo de dispositivo y ocultamos o no el slidebar de la vista de escritorio

    if (/android/i.test(navigator.userAgent)) {
      this.toggleoptions = false;
    }
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      this.toggleoptions = false;
    }
    }
    
  },

  created() {
  this.verificarDisposito();
  }
};
</script>

<style scoped>
i {
  margin-right: 10px;
}
section {
  padding: 0px;
}
label {
  font-family: "Poppins";
  font-size: 14px;
}
.container-slidebar {
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 20vw;
  height: 100vh;
}
.bar-dashboard {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15vh;
  min-height: 80px;
  min-width: 200px;
  padding: 10px 15px 20px 15px;
  box-shadow: 0px 1px 8px rgba(128, 128, 128, 0.2);
}
.logo-img {
  padding: 10px;
  z-index: 99;
}
.opciones {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  min-width: 200px;
  list-style-type: none;
  padding-left: 10px;
  background-color: rgba(228, 36, 19, 0.03);
  margin-bottom: 0px;
}
li {
  display: flex;
  /* background-color: rgb(255, 255, 255); */
  width: 100%;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.item {
  display: flex;
  width: 100%;
  padding-left: 10px;
  /* background-color: rgb(255, 255, 255); */
  height: 7%;
  min-height: 50px;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  z-index: 1;
}

.item:hover {
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.2);
}
.item:active {
  background-color: rgba(228, 36, 19, 0.05);
}
label:hover {
  cursor: pointer;
}
.toggle-menu {
  display: none;
}
@media (min-width: 360px) and (max-width: 768px) {
  .container-slidebar {
    padding: 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
    height: min-content;
  }
  .opciones {
    overflow: auto;
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    padding-bottom: 15px;
  }
  .toggle-menu {
    display: flex;
    margin-right: 10px;
    transform: scale(1.9);
  }
  .bar-dashboard {
    width: 100%;
    justify-content: space-between;
    height: 5vh;
    padding: 0px;
    min-height: 75px;
  }
  .logo-img {
    display: block;
    height: 100%;
    padding: 15px;
  }
  .bx-menu:hover {
    cursor: pointer;
    color: gray;
  }
}
</style>