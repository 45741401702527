<template>
  <div class="container-tabla">
    <table>
      <div class="theads">
        <thead class="thead-info">
          <th class="usuario regt">Usuario</th>
          <th class="rol regt">Rol</th>
          <th class="numid regt">No. Identifcación</th>
          <!-- <th class="pass regt">Contraseña</th> -->
          <th class="correo regt">Correo</th>
          <th class="navbar opt" @click="nuevousuario">
            <span>Nuevo</span> <i class="bx bx-user-plus"></i>
          </th>
        </thead>
        <thead class="thead-create" v-if="newuser" id="thead-create">
          <th class="usuario regt">
            <input
              class="inp-newuser"
              type="text"
              placeholder="Nombre"
              v-model="nombreModel"
            />
          </th>
          <th class="rol regt">
            <select name="rols" id="rols" v-model="rolModel">
              <option value="psicologo">Psicologo</option>
              <option value="deporte">Deporte</option>
              <option value="salud">Salud</option>
              <option value="cultura">Cultura</option>
              <option value="admin">Admin</option>
            </select>
          </th>
          <th class="numid regt">
            <input
              type="number"
              class="inp-newuser"
              placeholder="Numero CC"
              v-model="nidModel"
            />
          </th>
          <th class="pass regt">
            <input
              type="text"
              class="inp-newuser"
              placeholder="Contraseña"
              v-model="pasModel"
            />
          </th>
          <th class="correo regt">
            <input
              type="email"
              class="inp-newuser"
              placeholder="Correo Electronico"
              v-model="mailModel"
            />
          </th>
          <button class="btn-crearusr" @click="crear">Crear</button>
        </thead>
      </div>

      <tbody>
        <tr v-for="dato of datos" :key="dato._id">
          <RowUserList
            class="row-user"
            :nombreusuario="dato.nombreusuario"
            :rol="dato.rol"
            :numeroidentificacion="dato.numeroidentificacion"
            :correoelectronico="dato.correoelectronico"
            @recargar="reload"
          />
          <!-- :password="dato.password" -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import RowUserList from "@/components/privado/RowUserList.vue";
import Api from "@/logic/ApiUsers.js";
// import Alert from "@/components/Alert.vue";
import localToken from "@/logic/localToken.js";

export default {
  name: "UsuariosFragment",
  data: function () {
    return {
      datos: [],
      newuser: false,
      nombreModel: "",
      rolModel: "",
      nidModel: "",
      pasModel: "",
      mailModel: "",
      iserror: false,
    };
  },
  components: {
    RowUserList,
  },
  methods: {
    nuevousuario() {
      this.newuser = !this.newuser;
    },
    async crear() {
      this.ShowLoadAnimation(true);
      console.log(this.rolModel);
      if (
        (this.nombreModel != "") &
        (this.rolModel != "") &
        (this.nidModel != "") &
        (this.pasModel != "") &
        (this.mailModel != "") &
        (isNaN(this.nidModel) != true)
      ) {
        const creacion = {
          nombreusuario: this.nombreModel,
          rol: this.rolModel,
          numeroidentificacion: this.nidModel,
          password: this.pasModel,
          correoelectronico: this.mailModel,
          token: localToken.getToken(),
        };

        await Api.create(creacion)
          .then((resp) => {
            if (resp.status === 200) {
              this.obtenerUsers();
              this.newuser = false;
              this.nombreModel = "";
              this.rolModel = "";
              this.nidModel = "";
              this.pasModel = "";
              this.mailModel = "";
              this.ShowLoadAnimation(false);
            }
            this.ShowLoadAnimation(false);
          })
          .catch((err) => {
            this.ShowLoadAnimation(false);
            console.log(err.response);
            if (err.status === 400) {
              this.$alert(err.response.data, "Info", {
                confirmButtonText: "Aceptar",
                callback: () => {
                  return;
                },
              });
            }
          });
      } else {
        this.ShowLoadAnimation(false);
        this.iserror = true;
        // alert('Todos los campos deben ser completados y la idenficacion debe contener solo numeros.')
      }
    },

    async obtenerUsers() {
      try {
        if (localToken.getToken() == null) {
          this.$router.push("/login");
        } else {
          const respuesta = await Api.getAllUsers({
            token: localToken.getToken(),
          });
          this.datos = respuesta.data;
          this.$emit("nocargar");
          console.log(this.datos);
        }
      } catch (error) {
        console.log(error);
      }
    },
    reload() {
      this.obtenerUsers();
    },
    ShowLoadAnimation(valor) {
      if (valor) {
        this.$store.dispatch("setLoadAnimationAction", { s: true });
      } else {
        setTimeout(() => {
          this.$store.dispatch("setLoadAnimationAction", { s: false });
        }, 500);
      }
    },
  },

  created() {
    if (localToken.getToken() == "null") {
      this.$router.push("/login");
    } else {
      this.ShowLoadAnimation(true);
      this.obtenerUsers();
    }
  },
  mounted() {
    this.ShowLoadAnimation(false);
  },
};
</script>

<style scoped>
.container-tabla {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
table {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 20px 15px 15px 15px;
  border-radius: 15px;
}
.inp-newuser {
  border: none;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.07);
}
.inp-newuser:focus {
  outline: none;
}
.btn-crearusr {
  display: flex;
  height: 100%;
  border: 1px solid rgba(128, 128, 128, 0.4);
  border-radius: 3px;
  padding: 2px 10px 2px 10px;
  justify-content: center;
  align-items: center;
  color: rgba(128, 128, 128, 1);
}
.btn-crearusr:hover {
  color: rgba(0, 0, 0, 0.7);
}
thead {
  display: flex;
  width: 100%;
  height: calc(15vh - 40px);
  min-height: 55px;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  background-color: rgba(128, 128, 128, 0.2);
  box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.2);

  /* background-color: rgb(228,35,19,1); */

  border-radius: 10px;
}
thead > th {
  font-family: "Poppins";
  font-size: 14px;
  display: flex;
  align-items: center;
}
.edit-menu > li > span {
  font-family: "Poppins";
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.edit-menu > li > span:hover {
  cursor: pointer;
  color: rgb(228, 35, 19);
}

.usuario {
  min-width: 150px;
  width: 25%;
  max-width: 25%;
}
.rol {
  width: 10%;
  min-width: 60px;
  max-width: 10%;
}
.numid {
  width: 15%;
  min-width: 150px;
  max-width: 15%;
}
.pass {
  min-width: 15%;
  max-width: 15%;
}
.estado {
  min-width: 10%;
  max-width: 10%;
}
.correo {
  min-width: 15%;
  max-width: 15%;
}
.opt {
  min-width: 10%;
  max-width: 10%;
  justify-content: center;
}
.opt i {
  font-size: 20px;
  margin-left: 5px;
}
.opt i:hover {
  color: black;
}
.opt:hover {
  cursor: pointer;
  border-bottom: 1px solid rgba(128, 128, 128, 0.397);
}
.regt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
tbody {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
tbody {
  overflow: auto;
}
th {
  display: flex;
  width: 100%;
}
th > li {
  list-style-type: none;
}
.edit-menu {
  transform: translateX(-120px);
  max-width: 150px;
  z-index: 200;
}
.drop {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  flex-wrap: wrap;
  white-space: pre-wrap;
  text-align: center;
}
.thead-create {
  height: calc(10vh - 40px);
}
#rols {
  border: none;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.07);
}

@media (min-width: 360px) and (max-width: 768px) {
  .theads {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: fixed;
  }
  .thead-info {
    width: min-content;
    min-width: 50px;
    padding: 0px;
    height: min-content;
    min-height: 50px;
    margin: 0px;
  }
  .thead-info > .usuario,
  .thead-info > .rol,
  .thead-info > .numid,
  .thead-info > .pass,
  .thead-info > .correo {
    display: none;
  }
  .thead-create {
    flex-direction: column;
    height: max-content;
    background-color: #f4fbfe;
    margin-top: 10px;
    max-width: 80vw;

    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.548);
  }
  .thead-create > th {
    margin: 5px 10px 5px 10px;
    overflow: visible;
  }

  .container-tabla {
    flex-direction: row;
  }
  table {
    overflow: auto;
  }
  .edit-menu {
    transform: translateX(20px);
    max-width: 150px;
    z-index: 200;
  }
  tbody {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
  }
  .opt {
    max-width: 100%;
  }
  .opt span {
    display: none;
  }
  .opt i {
    font-size: 20px;
  }
  .opt:hover {
    border: none;
  }
}
</style>
