<template>
  <div class="contenedorCarga">
    <div class="itemCarga" v-if="false">
      <label>Cargar Estudiantes</label>
      <!-- <v-file-input v-model="archivo" placeholder="Subir Archivo" label="Subir Archivo"/> -->
      <input
        type="file"
        id="db-estudiantes"
        name="db"
        accept=".csv"
        @change="cargar"
        hidden
      />
      <div>
        <p>Click en el icono para cargar una nueva base de datos de estudiantes.</p>
        <i class="bx bx-cloud-upload" @click="showmessage=!showmessage"></i>
      </div>
      
      <div v-if="showmessage" class="confirmacion">
        <p>Esta accion no tiene reversa, desea continuar?</p>
        <label class="not" @click="showmessage=!showmessage">NO</label>
        <label class="yes" for="db-estudiantes">SI</label>
      </div>
    </div>

    <div class="itemCarga">
      <label>Cargar Caracterizacion</label>
      
      <input
        type="file"
        id="db-caracterizacion"
        name="dbc"
        accept=".csv"
        @change="cargarC"
        hidden
      />
      <div>
        <p>Click en el icono para cargar una nueva base de datos de Caracterizacion.</p>
        <i class="bx bx-book-reader" @click="showmessage2=!showmessage2"></i>
      </div>
      
      <!-- lanzador del del evento click al input -->
      <div v-if="showmessage2" class="confirmacion">
        <p>Esta accion no tiene reversa, desea continuar?</p>
        <label class="not" @click="showmessage2=!showmessage2">NO</label>
        <label class="yes" for="db-caracterizacion">SI</label>
      </div>
    </div>

    
  </div>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import localToken from "@/logic/localToken.js"
export default {
  name: "CargayDescargaFragment",
  data:function(){
    return{
      showmessage:false,
      showmessage2:false,
    
    }
  },
  methods: {
    
    async cargar() {
      try {
        const entrada = document.getElementById("db-estudiantes");
        const formData = new FormData()
        
        formData.append('db', entrada.files[0], 'db.csv')
        console.log(entrada.files)
        if (entrada.files.length > 0) {
          this.showmessage = false;
          alert("Este proceso se esta ejecutando en segundo plano, por favor no lo ejecute nuevamente, tiempo estimado 30 minutos.");
          formData.append('token', localToken.getToken())
          const respuesta = await ApiUsers.loadUsers(formData);

          console.log(respuesta.status);
          document.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    },
     async cargarC() {
      try {
        const entrada = document.getElementById("db-caracterizacion");
        const formData = new FormData()
        
        formData.append('dbc', entrada.files[0], 'dbc.csv')
        console.log(entrada.files)
        if (entrada.files.length > 0) {
          this.showmessage = false;
          alert("Este proceso se esta ejecutando en segundo plano, por favor no lo ejecute nuevamente, tiempo estimado 30 minutos.");
          formData.append('token', localToken.getToken())
          const respuesta = await ApiUsers.loadCaracterizacion(formData);

          console.log(respuesta.status);
          document.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    },
    ShowLoadAnimation(valor){
      if(valor){
         this.$store.dispatch('setLoadAnimationAction',{s:true})
      }else{
        setTimeout(() => {
        this.$store.dispatch('setLoadAnimationAction',{s:false})
      }, 500); 
      }
      
    },
 
  },

  created() {
    this.ShowLoadAnimation(true)
  },
  mounted() {
   this.ShowLoadAnimation(false);
  },
};
</script>

<style >
.contenedorCarga {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.itemCarga {
  padding: 10px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 12vh;
  
  height: min-content;
  width: 100%;
  margin-top: 15px;
  border-radius: 7px;
  box-shadow: 0px 3px 8px rgba(128, 128, 128, 0.4);
}
.itemCarga > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.itemCarga > div > p {
  display: flex;
  width: 70%;
  font-size: 12px;
}
.itemCarga > div > i {
  margin: 15px 25px 5px 5px;
  transform: scale(3);
  
}
.confirmacion {
  box-sizing: border-box;
  background-color: rgb(255, 70, 70);
  border-radius: 5px;
  padding: 2px 10px 2px 10px;
  align-items: center;
  z-index: 1;
  margin-top: 10px;
}
.confirmacion > p {
  margin: 0px;
}
.confirmacion > label:hover {
  cursor: pointer;
  }
.bx:hover{
  cursor: pointer;
  color: rgb(43, 177, 43);
}

.not:hover,.yes:hover{
  color: rgb(206, 198, 198);
}
@media (min-width: 768px)  {
  .itemCarga{
    width: 40%;
    min-width: 250px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .contenedorCarga{
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    height: min-content;
  }
  .confirmacion{
    padding-top: 2px;
  }
}

</style>