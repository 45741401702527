<template>
  <!-- id,nombre,segundonombre,apellido,segundoapellido,tipoidentificacion,numeroidentificacion,fechacumpleanios,edad,correoelectronico,direccion,ciudad,departamento,campus,grado,semestre,periodo,facultad,programa -->
  <el-dialog
    title="Crear estudiante"
    :visible.sync="dialogVisible"
    width="70%"
    :before-close="clear"
  >
    <el-form
      @submit.prevent.native=""
      :model="ruleForm"
      :ref="$options.name"
      size="small"
    >
      <el-row :gutter="12">
        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item
            label="ID Estudiante"
            prop="id"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-input v-model="ruleForm.id" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item
            label="Nombre"
            prop="nombre"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-input v-model="ruleForm.nombre"></el-input>
          </el-form-item>
        </el-col>

        <!-- <el-col :md="24" :lg="6" :xl="6">
                    <el-form-item label="Segundo Nombre" prop="segundonombre">
                        <el-input v-model="ruleForm.segundonombre"></el-input>
                    </el-form-item>
                </el-col> -->

        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item
            label="Apellido"
            prop="apellido"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-input v-model="ruleForm.apellido"></el-input>
          </el-form-item>
        </el-col>

        <!-- <el-col :md="24" :lg="6" :xl="6">
                    <el-form-item label="Segundo Apellido" prop="segundoapellido">
                        <el-input v-model="ruleForm.segundoapellido"></el-input>
                    </el-form-item>
                </el-col> -->

        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item
            label="Tipo Identificación"
            prop="tipoidentificacion"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="ruleForm.tipoidentificacion"
              style="width: 100%"
            >
              <el-option label="Cédula de Ciudadanía" value="CC" />
              <el-option label="Tarjeta de Identidad" value="TI" />
              <el-option label="Registro Civil" value="RC" />
              <el-option label="Cédula de Extranjería" value="CE" />
              <el-option label="Pasaporte" value="PA" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item
            label="No. Identificación"
            prop="numeroidentificacion"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-input v-model="ruleForm.numeroidentificacion"></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="24" :lg="12" :xl="12">
          <el-form-item
            label="Programa"
            prop="programa"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-input v-model="ruleForm.programa"></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item
            label="Periodo"
            prop="periodo"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-input v-model="ruleForm.periodo"></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item
            label="Grado"
            prop="grado"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="ruleForm.grado"
              value-key=""
              placeholder="--- Seleccione ---"
              style="width: 100%"
            >
              <el-option label="Pregrado" value="Pregrado" />
              <el-option label="Posgrado" value="Posgrado" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item
            label="Semestre"
            prop="semestre"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-input v-model="ruleForm.semestre"></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item
            label="Fecha Cumpleaños"
            prop="fechacumpleanios"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-date-picker
              type="date"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              placeholder="Fecha Cumpleaños"
              style="width: 100%"
              v-model="ruleForm.fechacumpleanios"
              @change="edad"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :md="24" :lg="6" :xl="6">
          <el-form-item label="Edad" prop="edad">
            <el-input v-model="ruleForm.edad" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="24" :lg="12" :xl="12">
          <el-form-item
            label="Correo Electrónico"
            prop="correoelectronico"
            :rules="[
              { required: true, message: 'Campo requerido', trigger: 'blur' },
            ]"
          >
            <el-input v-model="ruleForm.correoelectronico"></el-input>
          </el-form-item>
        </el-col>

        <!-- <el-col :md="24" :lg="12" :xl="12">
                    <el-form-item label="Direccion" prop="direccion" :rules="[{ required: true, message: 'Campo requerido', trigger: 'blur' }]">
                        <el-input v-model="ruleForm.direccion"></el-input>
                    </el-form-item>
                </el-col> -->

        <!-- <el-col :md="24" :lg="6" :xl="6">
                    <el-form-item label="Ciudad" prop="ciudad" :rules="[{ required: true, message: 'Campo requerido', trigger: 'blur' }]">
                        <el-input v-model="ruleForm.ciudad"></el-input>
                    </el-form-item>
                </el-col> -->

        <!-- <el-col :md="24" :lg="6" :xl="6">
                    <el-form-item label="Departamento" prop="departamento" :rules="[{ required: true, message: 'Campo requerido', trigger: 'blur' }]">
                        <el-input v-model="ruleForm.departamento"></el-input>
                    </el-form-item>
                </el-col> -->

        <!-- <el-col :md="24" :lg="6" :xl="6">
                    <el-form-item label="Campus" prop="campus" :rules="[{ required: true, message: 'Campo requerido', trigger: 'blur' }]">
                        <el-input v-model="ruleForm.campus"></el-input>
                    </el-form-item>
                </el-col> -->

        <!-- <el-col :md="24" :lg="6" :xl="6">
                    <el-form-item label="Grado" prop="grado" :rules="[{ required: true, message: 'Campo requerido', trigger: 'blur' }]">
                        <el-input v-model="ruleForm.grado"></el-input>
                    </el-form-item>
                </el-col> -->

        <!-- <el-col :md="24" :lg="6" :xl="6">
                    <el-form-item label="Facultad" prop="facultad" :rules="[{ required: true, message: 'Campo requerido', trigger: 'blur' }]">
                        <el-input v-model="ruleForm.facultad"></el-input>
                    </el-form-item>
                </el-col> -->
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="dialogVisible = false">Cancel</el-button> -->
      <el-button type="primary" @click="createEstudiante">Guardar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";

export default {
  name: "FormEstudiante",
  data() {
    return {
      ruleForm: {
        id: "",
        nombre: "",
        segundonombre: "",
        apellido: "",
        segundoapellido: "",
        tipoidentificacion: "",
        numeroidentificacion: "",
        fechacumpleanios: "",
        edad: "",
        correoelectronico: "",
        direccion: "",
        ciudad: "",
        departamento: "",
        campus: "",
        grado: "",
        semestre: "",
        periodo: "",
        facultad: "",
        programa: "",
      },
      dialogVisible: false,
    };
  },
  methods: {
    createEstudiante() {
      try {
        this.$refs[this.$options.name].validate(async (valid) => {
          if (valid) {
            await ApiUsers.createEstudiante(this.ruleForm)
              .then((resp) => {
                console.log("resp: ", resp);
                if (resp.status === 200) {
                  this.$emit("loadEstudiante");
                  this.dialogVisible = false;
                }
              })
              .catch((err) => {
                console.log("err: ", err.response);
                if (err.status === 400) {
                  this.$alert(err.response.data.message, "Info", {
                    confirmButtonText: "Aceptar",
                    // eslint-disable-next-line no-unused-vars
                    callback: (action) => {
                      return;
                    },
                  });
                }
              });
            // console.log(resp) // resp.id
            // if (resp.status === 200) {
            //     this.$emit('loadEstudiante');
            //     this.dialogVisible = false;
            // }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } catch (error) {
        console.log(error.response);
      }
    },
    edad(value) {
      this.ruleForm.edad = this.calcularEdad(value);
    },
    calcularEdad(fechaNacimiento) {
      const hoy = new Date();
      const fechaNac = new Date(fechaNacimiento);
      let edad = hoy.getFullYear() - fechaNac.getFullYear();
      const mes = hoy.getMonth() - fechaNac.getMonth();

      if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNac.getDate())) {
        edad--;
      }

      return edad;
    },
    clear() {
      this.$refs[this.$options.name].resetFields();
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped></style>
