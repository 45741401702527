<template>
  <section class="container-board">
    <LoadFragment v-show="$store.state.loadAnimation" />
    <DesarrolloFragment v-if="this.$store.state.optionSlidebarSelected == 1" />
    <CaracterizacionFragment v-if="this.$store.state.optionSlidebarSelected == 7" />
    <RecreacionFragment v-if="this.$store.state.optionSlidebarSelected == 2" />
    <CulturaFragment v-if="this.$store.state.optionSlidebarSelected == 3" />
    <SaludFragment v-if="this.$store.state.optionSlidebarSelected  == 4" />
    <UsuariosFragment v-if="this.$store.state.optionSlidebarSelected == 5"  />
    <ReportesGenerales v-if="this.$store.state.optionSlidebarSelected == 8"  />
    <CargayDescargaFragment v-if="this.$store.state.optionSlidebarSelected == 6" />
    <ApsicologicaFragment v-if="this.$store.state.optionSlidebarSelected == 9" />
  </section>
</template>

<script>
import UsuariosFragment from "@/components/privado/UsuariosFragment.vue";
import DesarrolloFragment from "@/components/privado/DesarrolloFragment.vue";
import CaracterizacionFragment from "@/components/privado/CaracterizacionFragment.vue";
import CulturaFragment from "@/components/privado/CulturaFragment.vue";
import SaludFragment from "@/components/privado/SaludFragment.vue";
import RecreacionFragment from "@/components/privado/RecreacionFragment.vue";
import CargayDescargaFragment from "@/components/privado/CargayDescargaFragment.vue";
import ReportesGenerales from "@/components/privado/ReportesGenerales.vue";
import LoadFragment from "@/components/general/LoadFragment.vue";
import {startMonitorActivity} from "@/logic/timeOut.js"
import localToken from '@/logic/localToken.js'
import ApsicologicaFragment from '@/components/privado/ApsicologicaFragment.vue'

export default {
  name: "Board",
  components: {
    UsuariosFragment,
    DesarrolloFragment,
    CulturaFragment,
    SaludFragment,
    RecreacionFragment,
    CargayDescargaFragment,
    LoadFragment,CaracterizacionFragment,
    ReportesGenerales,
    ApsicologicaFragment
  },
  
  updated(){
    
   
  
    //si expira el token redirecciona al login
    if (localToken.getToken() == "null") {
      this.$router.push("/login");
    }
  },
  mounted(){
    //iniciamos el monitor inactividad para expirar o no el token
    startMonitorActivity();
  }
};
</script>

<style scoped>
section {
  padding: 0px;
}
.container-board {
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 80vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
}
@media (min-width: 360px) and (max-width: 768px) {
  .container-board{

    max-width: 100vw;
  }
}
</style>