<template>
  <section id="team" class="team section-bg" >
     
    <Alerta class="alerta" v-show="$store.state.showrutadescape" :titulo="smsTitle" :cuerpo="smsBody" :tBoton="smsBText" />
    <div class="container">
      <div class="section-title">
        <h2>Desarrollo Humano</h2>
        <p>Inscribete a las actividades disponibles.</p>
      </div>
      <div class="input-grupo-container">
        <div v-show="showOneSection" class="input-grupo">
          <h5>Rol de Inscripcion:</h5>
          <select class="custom-select" v-model="seleccion">
            <!-- <option selected>Selecione</option> -->
            <option value="estudiante">Estudiante</option>
            <option value="docente">Docente</option>
            <option value="administrativo">Administrativo</option>
            <option value="egresado">Egresado</option>
            <option value="estudiante-intercambio">
              Estudiante Intercambio
            </option>
            <option value="invitado-externo">Invitado Externo</option>
          </select>
          <div class="input-group-append p-3">
            <button
              class="btn btn-outline-primary"
              type="button"
              @click="selectRol"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>

      <div v-show="showInputCodigo" class="container p-2 col-lg-6">
        <div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Codigo del Estudiante</label>
            <input
              min="0"
              type="number"
              class="form-control"
              
              placeholder="Ej: 123456..."
              v-model="codigoEstudiante"
              @keydown.enter="loadEstudiante"
            />
          </div>
        </div>
        <div class="input-group-append p-3">
          <button
            class="btn btn-outline-primary"
            type="button"
            @click="loadEstudiante"
          >
            Siguiente
          </button>
        </div>
      </div>

      <div v-show="showInputDatos" class="container p-2 col-lg-6">
        <form>
          <div class="form-group">
            <label for="exampleFormControlInput1">Nombre Completo</label>
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Ej: Yanet Vergara Amador"
              v-model="nombreCompleto"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1"># Identificacion</label>
            <input
              min="0"
              type="number"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Ej: 1234567890"
              v-model="identificacion"
            />
          </div>

          <!-- <div class="form-group">
            <label for="exampleFormControlSelect1"># Idenficacion</label>
            <select class="form-control" id="exampleFormControlSelect1">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect2"
              >Example multiple select</label
            >
            <select
              multiple
              class="form-control"
              id="exampleFormControlSelect2"
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Example textarea</label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div> -->
        </form>
        <div class="input-group-append p-3">
          <button
            class="btn btn-outline-primary"
            type="button"
            @click="loadOtros"
          >
            Siguiente
          </button>
        </div>
      </div>

      <div v-show="showActividades" class="row">
        <div
          class="col-lg-3 col-md-6 d-flex align-items-stretch justify-content-center"
          v-for="actividad in actividades"
          :key="actividad._id"
        >
          <div class="member">
            <div class="member-img">
              <img :src="actividad.imagen" class="img-fluid activ-img" alt="" />
              <div class="social">
                <button
                  class="btn btn-success"
                  @click="inscribir(actividad._id)"
                >
                  Inscribirme
                </button>
              </div>
            </div>
            <div class="member-info">
              <h4>{{ actividad.nombre }}</h4>
              <div class="row">
                <h6 class="col-2">Lugar:</h6>
                <span class="col-10">{{ actividad.lugar }}</span>
              </div>
              <div class="row">
                <h6 class="col-2">Hora:</h6>
                <span class="col-10">{{
                  `${actividad.horainicio} - ${actividad.horafin}`
                }}</span>
              </div>
              <div class="row">
                <h6 class="col-2">Fecha:</h6>
                <span class="col-10">{{
                  `${actividad.fechainicio} - ${actividad.fechafin}`
                }}</span>
              </div>
               <div class="row">
                <h6 class="col-4 d-flex inscritos ">Inscritos:</h6>
                <span class="col-8 pl-0">{{
                   actividad.participantes.length
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-show="showNone">
          <h4>No hay actividades, vuele luego.</h4>
        </div>
      </div>
    </div>

    <form-estudiante ref="formEstudiante" @loadEstudiante="loadEstudiante" />
  </section>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
import Alerta from "@/components/general/Alerta.vue";
import FormEstudiante from "@/components/FormEstudiante";
//const ObjectId = require('mongo-objectid');
export default {
  name: "InscripActividades",
  data: function () {
    return {
      nombreCompleto: null,
      identificacion: null,
      codigoEstudiante: null,
      seleccion: "estudiante",
      showInputCodigo: false,
      showInputDatos: false,
      showActividades: false,
      showOneSection: true,
      showNone: false,
      smsTitle:"Mensaje",
      smsBody:"Cuerpo",
      smsBText:"Clickme",
      
      
      smsShow:false,
      actividades: [],
    };
  },
  methods: {
   
    selectRol() {
      if (this.seleccion == "estudiante") {
        this.showInputCodigo = true;
        this.showInputDatos = false;
        this.showOneSection = false;
      } else {
        this.showInputDatos = true;
        this.showInputCodigo = false;
        this.showOneSection = false;
      }
    },
    async loadEstudiante() {
      if (this.codigoEstudiante == null) {
         this.smsBody = "Debes ingresar un codigo.";
              this.smsBText = "Salir";
              //this.$store.state.valueSRE = true;
              //this.$store.state.rutadeescape = '/servicios';
              //this.$store.dispatch("actionShowRutaDeEscape");
      } else {
        try {
          const datos = await ApiUsers.existeEstudiante(this.codigoEstudiante);
          if (datos.data == true) {
            this.showInputCodigo = false;
            this.showActividades = true;
          } else {
            this.$confirm('El estudiante no existe en la base de datos. ¿Desea crearlo?', 'Info', {
              confirmButtonText: 'Si',
              cancelButtonText: 'No',
              type: 'Info'
            }).then(() => {
              this.$refs.formEstudiante.dialogVisible = true;
              this.$refs.formEstudiante.ruleForm.id = this.codigoEstudiante;
            }).catch(() => {
              this.$message({
                type: 'info',
                message: 'Delete canceled'
              });
            });
              // this.smsBody = "Este codigo no registra en la base de datos.";
              // this.smsBText = "Salir";
              // this.$store.state.valueSRE = true;
              // this.$store.state.rutadeescape = '/servicios';
              // this.$store.dispatch("actionShowRutaDeEscape");
              
            
          }
        } catch (error) {
          console.log(error);
           this.smsBody = "Ocurrio un error interno, intente mas tarde.";
              this.smsBText = "Salir";
              this.$store.state.valueSRE = true;
              this.$store.state.rutadeescape = '/servicios';
              this.$store.dispatch("actionShowRutaDeEscape");
        }
      }
    },
    loadOtros() {
      if (this.nombreCompleto == null || this.identificacion == null) {
         this.smsBody = "Todos los campos deben ser diligenciados.";
              this.smsBText = "Salir";
              this.$store.state.valueSRE = true;
              this.$store.state.rutadeescape = '/servicios';
              this.$store.dispatch("actionShowRutaDeEscape");
      } else {
        this.showInputDatos = false;
        this.showActividades = true;
      }
    },
    async cargarActividades() {
      try {
        const resp = await ApiUsers.getActividadesDH();
        this.actividades = resp.data;
        console.log(this.actividades);
      } catch (error) {
        console.error(error);
        this.showNone = true;
      }
    },
    async inscribir(idA) {
      try {
        let respuesta = [];
        if (this.codigoEstudiante == null) {
          respuesta = await ApiUsers.InscribirEstudianteActividadDH(
            idA,
            this.nombreCompleto,
            this.identificacion,
            this.seleccion
          );
        } else {
          respuesta = await ApiUsers.InscribirEstudianteActividadDH(
            idA,
            null,
            this.codigoEstudiante,
            this.seleccion
          );
        }
        
        console.log(respuesta);

         this.smsBody = "Se ha inscrito satisfactoriamente,presione CERRAR para inscribirse en otra actividad.";
              this.smsBText = "Salir";
              this.$store.state.valueSRE = true;
              this.$store.state.rutadeescape = '/servicios';
              this.$store.dispatch("actionShowRutaDeEscape");
      } catch (error) {
          console.log(error.response)
          if(error.response.status == 409){
              this.smsBody = "Ya se encuentra inscrito, presione CERRAR para inscribirse en otra actividad.";
              this.smsBText = "Salir";
              this.$store.state.valueSRE = true;
              this.$store.state.rutadeescape = '/servicios';
              this.$store.dispatch("actionShowRutaDeEscape");
          }else{
              this.smsBody = "No se pudo realizar la inscripcion, intente mas tarde.";
              this.smsBText = "Salir";
              this.$store.state.valueSRE = true;
              this.$store.state.rutadeescape = '/servicios';
              this.$store.dispatch("actionShowRutaDeEscape");
          }
         
      }
    },
    
  },
  created() {
    this.cargarActividades();
    
  },
  // destroyed(){
  //   this.$store.state.publicdhselected = null;
  // },
  
  components:{
      Alerta,
      FormEstudiante,
  }
};
</script>

<style scoped>
.input-grupo-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.input-grupo {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
}
.input-grupo > select {
  min-height: 5vh;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
}
.input-grupo > div {
  display: flex;
  justify-content: center;
}
.input-grupo > div > button {
  margin-top: 6vh;
  width: 70%;
}
.activ-img {
  min-height: 180px;
  max-height: 180px;
}
.mensaje{
    height: 80vh;
    width: 90vw;
    position: fixed;
    top: calc(50% - 200px);
    left: calc(50% - (90vw/2));
}.team{
  height: 100vh;
    min-height: 76vh;
    overflow: auto;
  
}

.alerta{
    z-index: 99;
}
.inscritos{
    width: min-content;

    padding-right: 0px;
}
@media (min-width: 768px) {
  .input-grupo-container {
    padding-left:20vw ;
    padding-right:20vw ;
}
}

</style>