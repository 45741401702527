<template>
  <div class="home">
    <Header />
    <Banner/>
    <Services mode="1"/>
    <Footer/>
  </div>
</template>

<script>

import Header from '@/components/publico/Header.vue'
import Banner from '@/components/publico/Banner.vue'
import Footer from '../components/publico/Footer.vue'
import Services from '../components/publico/Services.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Banner,
    Footer,
    Services
  }
}
</script>
