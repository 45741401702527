<template>
  <section class="services section-bg">
    <div v-if="mode == '1'" class="container">
      <div class="section-title">
        <h2>PROCESOS EN LA PLATAFORMA</h2>
        <p>
          La plataforma de Bienestar Universitar les permite gestionar todos los
          formatos con los cuales la comunidad universitaria se vinvulan a los
          procesos desarrollados en: Desarrollo Humano, Deporte, Cultura y
          Salud.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-group"></i></div>
            <h4 class="title">
              <div @click="updateopt">Desarrollo Humano</div>
            </h4>
            <p class="description">
              Solicitudes, Asesorias, atencion estudiantil y Evaluacion de
              Actividades.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-basketball"></i></div>
            <h4 class="title">
              <div @click="updateopt">Recreacion y deporte</div>
            </h4>
            <p class="description">
              Insripciones a Equipos y Torneos, Asistencias, Prestamos y
              Evaluaciones de Satisfacción.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6" data-wow-delay="0.1s">
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-book-bookmark"></i></div>
            <h4 class="title">
              <div @click="updateopt">Cultura</div>
            </h4>
            <p class="description">
              Insripciones a Grupos y Eventos, Asistencias, Prestamos y
              Evaluaciones de Satisfacción.
            </p>
          </div>
        </div>
        <!-- <div class="col-lg-4 col-md-6" data-wow-delay="0.1s">
            <div class="icon-box">
              <div class="icon"><i class='bx bx-id-card' ></i></div>
              <h4 class="title"><router-link to="/servicios/dhumano/caracterizacion">Caracterizacion</router-link></h4>
              <p class="description">Aqui puedes realizar tu Encuenta de caracterizacion.</p>
            </div>
          </div> -->

        <!-- <div class="col-lg-4 col-md-6" data-wow-delay="0.2s">
            <div class="icon-box">
              <div class="icon"><i class="bi bi-globe" style="color: #d6ff22;"></i></div>
              <h4 class="title"><a href="">Nemo Enim</a></h4>
              <p class="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-wow-delay="0.2s">
            <div class="icon-box">
              <div class="icon"><i class="bi bi-clock" style="color: #4680ff;"></i></div>
              <h4 class="title"><a href="">Eiusmod Tempor</a></h4>
              <p class="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
            </div>
          </div> -->
      </div>
    </div>
    <div v-if="mode == '2'" class="container">
      <div class="section-title">
        <h2>PROCESOS EN LA PLATAFORMA</h2>
        <p>
          La plataforma de Bienestar Universitar les permite gestionar todos los
          formatos con los cuales la comunidad universitaria se vinvulan a los
          procesos desarrollados en: Desarrollo Humano, Deporte, Cultura y
          Salud.
        </p>
      </div>
      <div class="options-org">
        <select v-model="organiz" @change="cambiar()">
          <option value="1">Todos</option>
          <option value="0">Modulos</option>
        </select>
      </div>
      <!-- Todos los EndPoint -->
      <div v-if="$store.state.optorgservices == '1'" class="row">
        <!-- caracterizacion -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="dirigir(3, '/servicios/dh')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-group"></i></div>
            <h4 class="title">
              <a href="javascript:void(0)">Caracterizacion</a>
              <!-- <router-link to="/servicios/dh">Caracterizacion</router-link> -->
            </h4>
            <p class="description">Realiza tu encuesta de caracterizacion.</p>
          </div>
        </div>
        <!-- solicitar atencion psicologia -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="dirigir(4, '/servicios/dh')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-group"></i></div>
            <h4 class="title">
              <a href="javascript:void(0)">Solicitar Atencion Psicologica</a>
              <!-- <router-link to="/servicios/dh">Solicitar Atencion Psicologica</router-link> -->
            </h4>
            <p class="description">
              Aqui puedes solicitar tu cita psicologica.
            </p>
          </div>
        </div>
        <!-- evaluar atencion psicologica -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="dirigir(5, '/servicios/dh')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-group"></i></div>
            <h4 class="title">
              <a href="javascript:void(0)">Evaluar Atencion Psicologica</a>
              <!-- <router-link to="/servicios/dh">Evaluar Atencion Psicologica</router-link> -->
            </h4>
            <p class="description">
              Aqui puede evaluar la atencion psicologica que se te ha prestado.
            </p>
          </div>
        </div>
        <!-- inscripcion activdades desarrollo humano -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="dirigir(1, '/servicios/dh')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-group"></i></div>
            <h4 class="title">
              <div>Inscripcion Actividades de D. Humano</div>
            </h4>
            <p class="description">
              Aqui puede inscribirte a las actividades de desarrollo humano.
            </p>
          </div>
        </div>
        <!-- evaluacion actividades desarrollo humano -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="dirigir(2, '/servicios/dh')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-group"></i></div>
            <h4 class="title">
              <div>Evaluacion Actividades de D. Humano</div>
            </h4>
            <p class="description">
              Aqui puede evaluar a las actividades de desarrollo humano.
            </p>
          </div>
        </div>

        <!-- inscripcion gym -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="dirigir(1, '/servicios/rd')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-basketball"></i></div>
            <h4 class="title">
              <div>Inscripcion GYM</div>
            </h4>
            <p class="description">
              Aqui puedes inscribirte al Gymnasio universitario.
            </p>
          </div>
        </div>
        <!-- inscripcion torneos -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="dirigir(2, '/servicios/rd')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-basketball"></i></div>
            <h4 class="title">
              <div>Inscripcion Torneos</div>
            </h4>
            <p class="description">
              Aqui puedes inscribirte a los diferentes torneos disponibles.
            </p>
          </div>
        </div>
        <!-- inscripcion grupos -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="dirigir(3, '/servicios/rd')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-basketball"></i></div>
            <h4 class="title">
              <div>Inscripcion Grupos Deportivos</div>
            </h4>
            <p class="description">
              Aqui puedes inscribirte a los diferentes torneos disponibles.
            </p>
          </div>
        </div>
        <!-- otras actividades reacreacion y deportes -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="dirigir(4, '/servicios/rd')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-basketball"></i></div>
            <h4 class="title">
              <div>Otras Actividades R. y Deporte</div>
            </h4>
            <p class="description">
              Aqui puedes inscribirte a otras actividades programadas por la
              dependencia de reacreacion t deporte.
            </p>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="descargar('/formatoprestamoescenariodeportivo')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-basketball"></i></div>
            <h4 class="title">
              <div>Formato prestamo escenario deportivo</div>
            </h4>
            <p class="description">
              Aqui puedes descargar el Formato prestamo escenario deportivo.
            </p>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          @click="descargar('/formatoprestamoimplementodeportivocultural')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-basketball"></i></div>
            <h4 class="title">
              <div>Formato prestamo implementos deportivos</div>
            </h4>
            <p class="description">
              Aqui puedes descargar el Formato prestamo implementos deportivos.
            </p>
          </div>
        </div>
        <!-- inscripcion actvidades culturales -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          data-wow-delay="0.1s"
          @click="dirigir(1, '/servicios/cu')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-book-bookmark"></i></div>
            <h4 class="title">
              <div>Inscripcion Actividades Culturales</div>
            </h4>
            <p class="description">
              Aqui puedes inscribirte a las actividades culturales disponibles.
            </p>
          </div>
        </div>
        <!-- inscripcion grupos culturales -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          data-wow-delay="0.1s"
          @click="dirigir(2, '/servicios/cu')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-book-bookmark"></i></div>
            <h4 class="title">
              <div>Inscripcion a Grupos Culturales</div>
            </h4>
            <p class="description">
              Aqui puedes evaluar las actividades culturales en la que has
              participado.
            </p>
          </div>
        </div>
        <!-- prestamos implementos culturales -->
        <div
          class="col-lg-4 col-md-6 cursor-pointer"
          data-wow-delay="0.1s"
          @click="descargar('/formatoprestamoimplementodeportivocultural')"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-book-bookmark"></i></div>
            <h4 class="title">
              <div>Formato prestamo implementos culturales</div>
            </h4>
            <p class="description">
              Aqui puedes descargar el Formato prestamo implementos culturales.
            </p>
          </div>
        </div>
      </div>

      <!-- Endpoints por Modulos -->

      <div v-if="$store.state.optorgservices == '0'" class="row">
        <!-- Desarrollo Humano -->
        <div class="col-lg-4 col-md-6 cursor-pointer">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-group"></i></div>
            <h4 class="title">
              <router-link to="/servicios/dh">Desarrollo Humano</router-link>
            </h4>
            <p class="description">
              Solicitudes, Asesorias, atencion estudiantil y Evaluacion de
              Actividades.
            </p>
            <hr class="separador" />

            <div>
              <ul>
                <!-- Encuesta de caracterizacion  -->
                <li>
                  <div class="item-module" @click="dirigir(3, '/servicios/dh')">
                    <h6>Encuesta de caracterizacion</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>

                <hr />
                <!-- Solicitar atencion psicologica -->
                <li>
                  <div class="item-module" @click="dirigir(4, '/servicios/dh')">
                    <h6>Solicitar atencion psicologica</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>
                <!-- Evaluar atencion psicologica -->
                <li>
                  <div class="item-module" @click="dirigir(5, '/servicios/dh')">
                    <h6>Evaluar atencion psicologica</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>

                <hr />
                <!-- Inscripcion a actividades -->
                <li>
                  <div class="item-module" @click="dirigir(1, '/servicios/dh')">
                    <h6>Inscripcion a actividades</h6>
                    <i class="bx bxs-log-in-circle"></i>
                  </div>
                </li>
                <!-- Evaluacion de actividades -->
                <li>
                  <div class="item-module" @click="dirigir(2, '/servicios/dh')">
                    <h6>Evaluacion de actividades</h6>
                    <i class="bx bxs-log-in-circle"></i>
                  </div>
                </li>
                <!--  -->
              </ul>
            </div>
          </div>
        </div>
        <!-- Recreacion y deporte -->
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-basketball"></i></div>
            <h4 class="title">
              <div>Recreacion y deporte</div>
            </h4>
            <p class="description">
              Insripciones a Equipos y Torneos, Asistencias, Prestamos y
              Evaluaciones de Satisfacción.
            </p>
            <hr class="separador" />
            <div>
              <ul>
                <li>
                  <div class="item-module" @click="dirigir(1, '/servicios/rd')">
                    <h6>Inscripcion a gimnasio</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>
                <hr />
                <li>
                  <div class="item-module" @click="dirigir(2, '/servicios/rd')">
                    <h6>Inscripcion a torneos</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>
                <hr />
                <li>
                  <div class="item-module" @click="dirigir(3, '/servicios/rd')">
                    <h6>Inscripcion a grupos deportivos</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>
                <hr />
                <li>
                  <div class="item-module" @click="dirigir(4, '/servicios/rd')">
                    <h6>Inscripcion a otras actividades</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>
                <hr />
                <li>
                  <div
                    class="item-module"
                    @click="descargar('/formatoprestamoescenariodeportivo')"
                  >
                    <h6>Formato prestamo escenarios deportivos</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>
                <hr />
                <li>
                  <div
                    class="item-module"
                    @click="
                      descargar('/formatoprestamoimplementodeportivocultural')
                    "
                  >
                    <h6>Formato prestamo implementos deportivos</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Cultura -->
        <div class="col-lg-4 col-md-6" data-wow-delay="0.1s">
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-book-bookmark"></i></div>
            <h4 class="title">
              <router-link to="/servicios/cultura">Cultura</router-link>
            </h4>
            <p class="description">
              Insripciones a Grupos y Eventos, Asistencias, Prestamos y
              Evaluaciones de Satisfacción.
            </p>
            <hr class="separador" />
            <div>
              <ul>
                <li>
                  <div class="item-module" @click="dirigir(1, '/servicios/cu')">
                    <h6>Inscripcion a actividades</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>
                <hr />
                <li>
                  <div class="item-module" @click="dirigir(2, '/servicios/cu')">
                    <h6>Inscripcion a grupos</h6>
                    <div><i class="bx bxs-log-in-circle"></i></div>
                  </div>
                </li>
                <hr />
                <li>
                  <div
                    class="item-module"
                    @click="
                      descargar('/formatoprestamoimplementodeportivocultural')
                    "
                  >
                    <h6>Formato prestamo implementos culturales</h6>
                    <router-link to="dhumano"
                      ><i class="bx bxs-log-in-circle"></i
                    ></router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApiUsers from "@/logic/ApiUsers.js";
export default {
  name: "Services",
  props: {
    mode: String,
  },
  data: function () {
    return {
      organiz: this.$store.state.newoptorgservices,
    };
  },
  methods: {
    dirigir(valor, ruta) {
      this.$store.state.publicdhselected = valor;
      this.$router.push(`${ruta}`);
    },
    cambiar() {
      this.$store.state.newoptorgservices = this.organiz;
      this.$store.dispatch("updatesetOptOrgServicesAction");
    },
    updateopt() {
      this.$store.state.newoptorgservices = "0";
      this.$store.dispatch("updatesetOptOrgServicesAction");
      this.$router.push("/servicios");
    },
    async descargar(ruta) {
      try {
        ApiUsers.downStaticFile(ruta);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.icon-box {
  padding: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.bx:hover {
  color: rgba(228, 36, 19);
}
.services .icon {
  top: 20px;
}
.item-module {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.2);
  border-radius: 8px;
  padding: 3px 5px 3px 5px;
  margin-bottom: 8px;
  z-index: -50;
}
.item-module:hover {
  box-shadow: 0px 1px 5px rgba(228, 36, 19, 0.2);
  cursor: pointer;
}
.item-module > h6 {
  margin-bottom: 0px;
}
li {
  list-style-type: none;
}

.separador {
  color: rgba(102, 51, 153, 0);
}
.bx {
  color: black;
}
.options-org {
  display: flex;
  width: 100%;
  padding: 15px;
  margin-bottom: 25px;
}
.title > div:hover {
  color: rgba(228, 36, 19, 1);
  cursor: pointer;
}
</style>
