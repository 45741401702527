
export function startMonitorActivity() {
  var time;
  window.onload = resetTimer;
  // DOM Events
   document.onmousemove = resetTimer;
   document.onkeypress = resetTimer;

  
  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(()=>{
    
    window.sessionStorage.setItem("vue-compilation-version-0003453", null);
    window.location.reload();
    }, 300000);
    // 1000 milliseconds = 1 second300000
  }
}




