import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    optionSlidebarSelected:0,
    optionSlidebarSelected_temp:0,
    loadAnimation:true,
    loadAnimation_temp:null,



    login:false,
    rol:"none",
    newrol:null,
    optorgservices:'0',
    newoptorgservices:'0',
    rutadeescape:"/",
    
    showrutadescape:false,
    valueSRE:null,

    publicdhselected: null,
    actualCaracterizacion:null,
    newActualCaracterizacion:null
  },
  mutations: {
    setLoadAnimation(state,payl){
      state.loadAnimation = payl;
    },
  
    setOptionSlidebarSelected(state){
      state.optionSlidebarSelected = state.optionSlidebarSelected_temp;
    },


    setActualCaracterizacion(state){
      state.actualCaracterizacion = state.newActualCaracterizacion;
    },
    setRol(state){
      state.rol = state.newrol;
    },
    setOptOrgServices(state){
    
      state.optorgservices= state.newoptorgservices;
    },
    setRutaDeEscape(state){
      state.showrutadescape = state.valueSRE
    },
  },
  actions: {
    setLoadAnimationAction(context,valor){
         context.commit('setLoadAnimation',valor.s)
    },
    setOptionSlidebarSelectedAction(context){
      context.commit('setOptionSlidebarSelected')
    },
    newActualCaracterizacionAction(context){
      context.commit('setActualCaracterizacion')
    },
    addRolAction(context){
      context.commit('setRol')
    },
    updatesetOptOrgServicesAction(context){
      context.commit('setOptOrgServices')
    },
    actionShowRutaDeEscape(context){
      context.commit('setRutaDeEscape')
    }
  },
  getters: {
    actualDate(state){
      return state.actualCaracterizacion
    },
    isLogin(state){
      return state.login;
    },
    isCultura(state){
      if(state.rol=="cultura")
      return true
    },
    isDeporte(state){
      if(state.rol=="deporte")
      return true
    },
    isSalud(state){
      if(state.rol=="salud")
      return true
    },
    isPsicologo(state){
      if(state.rol=="psicologo")
      return true
    },
    isAdmin(state){
      if(state.rol=="admin")
      return true
    },
    rutadeescape(state){
      return state.rutadeescape
    }
  }
})
