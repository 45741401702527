var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"Crear estudiante","visible":_vm.dialogVisible,"width":"70%","before-close":_vm.clear},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:_vm.$options.name,attrs:{"model":_vm.ruleForm,"size":"small"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-row',{attrs:{"gutter":12}},[_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"ID Estudiante","prop":"id","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-input',{attrs:{"readonly":""},model:{value:(_vm.ruleForm.id),callback:function ($$v) {_vm.$set(_vm.ruleForm, "id", $$v)},expression:"ruleForm.id"}})],1)],1),_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"Nombre","prop":"nombre","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-input',{model:{value:(_vm.ruleForm.nombre),callback:function ($$v) {_vm.$set(_vm.ruleForm, "nombre", $$v)},expression:"ruleForm.nombre"}})],1)],1),_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"Apellido","prop":"apellido","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-input',{model:{value:(_vm.ruleForm.apellido),callback:function ($$v) {_vm.$set(_vm.ruleForm, "apellido", $$v)},expression:"ruleForm.apellido"}})],1)],1),_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"Tipo Identificación","prop":"tipoidentificacion","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.ruleForm.tipoidentificacion),callback:function ($$v) {_vm.$set(_vm.ruleForm, "tipoidentificacion", $$v)},expression:"ruleForm.tipoidentificacion"}},[_c('el-option',{attrs:{"label":"Cédula de Ciudadanía","value":"CC"}}),_c('el-option',{attrs:{"label":"Tarjeta de Identidad","value":"TI"}}),_c('el-option',{attrs:{"label":"Registro Civil","value":"RC"}}),_c('el-option',{attrs:{"label":"Cédula de Extranjería","value":"CE"}}),_c('el-option',{attrs:{"label":"Pasaporte","value":"PA"}})],1)],1)],1),_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"No. Identificación","prop":"numeroidentificacion","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-input',{model:{value:(_vm.ruleForm.numeroidentificacion),callback:function ($$v) {_vm.$set(_vm.ruleForm, "numeroidentificacion", $$v)},expression:"ruleForm.numeroidentificacion"}})],1)],1),_c('el-col',{attrs:{"md":24,"lg":12,"xl":12}},[_c('el-form-item',{attrs:{"label":"Programa","prop":"programa","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-input',{model:{value:(_vm.ruleForm.programa),callback:function ($$v) {_vm.$set(_vm.ruleForm, "programa", $$v)},expression:"ruleForm.programa"}})],1)],1),_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"Periodo","prop":"periodo","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-input',{model:{value:(_vm.ruleForm.periodo),callback:function ($$v) {_vm.$set(_vm.ruleForm, "periodo", $$v)},expression:"ruleForm.periodo"}})],1)],1),_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"Grado","prop":"grado","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value-key":"","placeholder":"--- Seleccione ---"},model:{value:(_vm.ruleForm.grado),callback:function ($$v) {_vm.$set(_vm.ruleForm, "grado", $$v)},expression:"ruleForm.grado"}},[_c('el-option',{attrs:{"label":"Pregrado","value":"Pregrado"}}),_c('el-option',{attrs:{"label":"Posgrado","value":"Posgrado"}})],1)],1)],1),_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"Semestre","prop":"semestre","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-input',{model:{value:(_vm.ruleForm.semestre),callback:function ($$v) {_vm.$set(_vm.ruleForm, "semestre", $$v)},expression:"ruleForm.semestre"}})],1)],1),_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"Fecha Cumpleaños","prop":"fechacumpleanios","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","placeholder":"Fecha Cumpleaños"},on:{"change":_vm.edad},model:{value:(_vm.ruleForm.fechacumpleanios),callback:function ($$v) {_vm.$set(_vm.ruleForm, "fechacumpleanios", $$v)},expression:"ruleForm.fechacumpleanios"}})],1)],1),_c('el-col',{attrs:{"md":24,"lg":6,"xl":6}},[_c('el-form-item',{attrs:{"label":"Edad","prop":"edad"}},[_c('el-input',{attrs:{"readonly":""},model:{value:(_vm.ruleForm.edad),callback:function ($$v) {_vm.$set(_vm.ruleForm, "edad", $$v)},expression:"ruleForm.edad"}})],1)],1),_c('el-col',{attrs:{"md":24,"lg":12,"xl":12}},[_c('el-form-item',{attrs:{"label":"Correo Electrónico","prop":"correoelectronico","rules":[
            { required: true, message: 'Campo requerido', trigger: 'blur' },
          ]}},[_c('el-input',{model:{value:(_vm.ruleForm.correoelectronico),callback:function ($$v) {_vm.$set(_vm.ruleForm, "correoelectronico", $$v)},expression:"ruleForm.correoelectronico"}})],1)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.createEstudiante}},[_vm._v("Guardar")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }